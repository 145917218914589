// import cookie from '@/plugins/cookie'

function aesDencrypt(txt) {
  const key = 'Pr4$eTy0 BoiL3rPlaT3'
  const CryptoJS = require('crypto-js')
  const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse('masprasmasprasya'),
    mode: CryptoJS.mode.CBC,
  })

  return CryptoJS.enc.Utf8.stringify(cipher).toString()
}

export const validation = function (form, schema, option = null) {
  const Validator = require('fastest-validator')
  let r = { status: false, message: 'no exe' }
  const v = new Validator(option)

  const check = v.compile(schema)
  const isValid = check(form)

  if (Array.isArray(isValid)) {
    const mess = {}
    for (let i = 0; i < isValid.length; i++) {
      const el = isValid[i]
      mess[el.field] = {
        type: el.type,
        message: el.message,
      }
    }
    r.message = mess
    r.status = false
  } else {
    r.message = 'oke'
    r.status = true
  }

  return r
}

export const guard = function (access) {
  const { getCookie } = require('@/plugins/cookie').default
  const permisObj = JSON.parse(getCookie('lpj'))
  const permis = permisObj ? permisObj.permissions : null

  //if (access == "all_access") return true;

  if (permis) {
    // const aes256 = require('aes256')

    // const permisDec = aes256.decrypt(key, permis)
    const permisDec = aesDencrypt(permis)
    // console.log(permisDec)

    if (permisDec.includes(access) || access == 'all_access') {
      return true
    } else return false
  } else return false
}
