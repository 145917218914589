import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  showConfirmButton: false,
  toast: true,
  //animation: true,
  timer: 3000,
  timerProgressBar: true,
  position: 'bottom',
  customClass: {
    popup: 'colored-toast',
  },
  iconColor: 'white',
})

export default Toast
