export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'User',
    to: '/user',
    icon: 'cil-puzzle',
  },
  {
    component: 'CNavTitle',
    name: 'Rooms',
  },

  {
    component: 'CNavGroup',
    name: 'Bunpou',
    to: '/bunpou',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'List',
        to: '/bunpou',
      },
      {
        component: 'CNavItem',
        name: 'Form',
        to: '/bunpou/form',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Suuji',
    to: '/suuji',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'List',
        to: '/suuji',
      },
      {
        component: 'CNavItem',
        name: 'Form',
        to: '/suuji/form',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Kanji',
    to: '/kanji',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'List',
        to: '/kanji',
      },
      {
        component: 'CNavItem',
        name: 'Form',
        to: '/kanji/form',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Test',
  },
  {
    component: 'CNavGroup',
    name: 'Question',
    to: '/question',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'List',
        to: '/question',
      },
      {
        component: 'CNavItem',
        name: 'Form',
        to: '/question/form',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Answers',
    to: '/answer',
    icon: 'cil-puzzle',
  },
  {
    component: 'CNavItem',
    name: 'Tests',
    to: '/test',
    icon: 'cil-puzzle',
  },
  {
    component: 'CNavTitle',
    name: 'Report',
  },
  {
    component: 'CNavGroup',
    name: 'ACL',
    to: '/permission',
    icon: 'cil-puzzle',
    permission: 'acl_access',
    items: [
      {
        component: 'CNavItem',
        name: 'Permission',
        to: '/permission',
      },
      {
        component: 'CNavItem',
        name: 'Access',
        to: '/access',
      },
    ],
  },
]
