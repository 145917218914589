import { h, resolveComponent } from 'vue'
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: { requiresAuth: true, permission: 'all_access' },
    children: [
      {
        path: '/permission',
        name: 'Permission List',
        meta: { permission: 'acl_access' },
        component: () => import('@/views/acl/PermissionList.vue'),
      },
      {
        path: '/access',
        name: 'Access List',
        meta: { permission: 'acl_access' },
        component: () => import('@/views/acl/AccessList.vue'),
      },
      {
        path: '/user',
        name: 'User List',
        meta: { permission: 'all_access' },
        component: () => import('@/views/user/UserList.vue'),
      },
      {
        path: '/bunpou',
        name: 'Bunpou Room List',
        meta: { permission: 'all_access' },
        component: () => import('@/views/bunpou/BunpouRoomList.vue'),
      },
      {
        path: '/bunpou/form/:id?',
        name: 'Bunpou Form',
        meta: { permission: 'all_access' },
        component: () => import('@/views/bunpou/BunpouForm.vue'),
      },
      {
        path: '/question',
        name: 'Question',
        meta: { permission: 'all_access' },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/question',
            name: 'Question List',
            meta: { permission: 'all_access' },
            component: () => import('@/views/question/QuestionList.vue'),
          },
          {
            path: '/question/form/:id?',
            name: 'Question Form',
            meta: { permission: 'all_access' },
            component: () => import('@/views/question/QuestionForm.vue'),
          },
        ],
      },
      {
        path: '/suuji',
        name: 'Suuji',
        meta: { permission: 'all_access' },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/suuji',
            name: 'Suuji Room List',
            meta: { permission: 'all_access' },
            component: () => import('@/views/suuji/SuujiList.vue'),
          },
          {
            path: '/suuji/form/:id?',
            name: 'Suuji Form',
            meta: { permission: 'all_access' },
            component: () => import('@/views/suuji/SuujiForm.vue'),
          },
        ],
      },
      {
        path: '/kanji',
        name: 'Kanji',
        meta: { permission: 'all_access' },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/kanji',
            name: 'Kanji Room List',
            meta: { permission: 'all_access' },
            component: () => import('@/views/kanji/KanjiList.vue'),
          },
          {
            path: '/kanji/form/:id?',
            name: 'Kanji Form',
            meta: { permission: 'all_access' },
            component: () => import('@/views/kanji/KanjiForm.vue'),
          },
        ],
      },
      ///
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { permission: 'all_access' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
    ],
  },

  {
    path: '/',
    redirect: '/login',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },

  // {
  //   path: '/ck',
  //   name: 'ck',
  //   component: () => import('@/views/CKEditor'),
  // },

  {
    path: '/ck4',
    name: 'ck4',
    component: () => import('@/views/CKEditor4'),
  },

  {
    path: '/:catchAll(.*)',
    name: 'Page404',
    component: () => import('@/views/pages/Page404'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

import { guard } from '@/plugins/utils'
router.beforeEach((to, from, next) => {
  let detectPersissionBoolean = true

  for (let i = 0; i < to.matched.length; i++) {
    const el = to.matched[i].meta.permission
    if (guard(el) || el == undefined) {
      detectPersissionBoolean = true
    } else {
      detectPersissionBoolean = false
      break
    }
  }

  // console.log(guard('all_access'))

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const cookie = require('@/plugins/cookie').default

    const permisObj = JSON.parse(cookie.getCookie('lpj'))
    const permis = permisObj ? permisObj.permissions : ''
    // console.log(permis, detectPersissionBoolean)
    if (permis == '') {
      next({ name: 'Login' })
    } else {
      if (detectPersissionBoolean == false) {
        if (from.name == null) {
          next({ name: 'Dashboard' })
        } else {
          next({ name: from.name })
        }
        const alert = require('sweetalert2')

        alert.fire({
          title: 'Not Permitted!',
          text: 'You not permitted to access this path (' + to.name + ')',
          icon: 'error',
        })
      } else {
        next()
      }
    }
  } else {
    //next({name:'Login'})
    next()
  }
})

export default router
